import { setLocalStorageItem } from './set-local-storage-item';
import {
  getDisplayGuidelinesTypeFromStore,
  getStorageKey,
} from '../selectors/guidelines-selectors';
import { getCategory } from '../../common/selectors/categories-selectors';
import { getForumData } from '../selectors/forum-data-selectors';
import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import { createAction } from 'redux-actions';

export const HIDE_GUIDELINES = 'guidelines/HIDE_GUIDELINES';
export const hideGuidelinesSuccess = createAction(HIDE_GUIDELINES);

export const SHOW_GUIDELINES = 'guidelines/SHOW_GUIDELINES';
export const showGuidelinesSuccess = createAction(SHOW_GUIDELINES);

const setStorageKey = (value, categoryId) => (dispatch, getState) => {
  const state = getState();
  const category = getCategory(state, categoryId);
  const forumData = getForumData(state);
  const type = getDisplayGuidelinesTypeFromStore(state, categoryId);
  const metaSiteId = getMetaSiteId(state);

  dispatch(value ? hideGuidelinesSuccess({ type }) : showGuidelinesSuccess({ type }));

  dispatch(
    setLocalStorageItem({
      key: getStorageKey(type, metaSiteId, forumData, category),
      value,
    }),
  );
};

export const hideGuidelines = categoryId => setStorageKey(true, categoryId);
export const showGuidelines = categoryId => setStorageKey(false, categoryId);
