import { MODAL_TYPE_CREATE_POST } from '../components/modals/post-create-modal/post-create-modal-type';
import createAction from '../../common/services/create-action';
import sanitizeContent from '../services/sanitize-content';
import { extractHashtagsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import { extractMentionsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/mentions-services';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getCategory } from '../../common/selectors/categories-selectors';
import { closeModal } from '../../common/modals/framework/store/modal-actions';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import {
  EXPERIMENT_CREATE_POST_CAPTCHA,
  EXPERIMENT_CREATE_POST_CAPTCHA_BY_SPAMMED_FLAG,
  EXPERIMENT_FORCE_CAPTCHA_BY_CONTENT,
  EXPERIMENT_ENABLE_SERVER_CAPTCHA,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { getIsForumSpammed, getCaptchaSettings } from '../selectors/forum-data-selectors';
import { userEventsPostCaptchaResolved, userEventsPostCaptchaShown } from './user-events';
import { getRichContentFeatures } from '@wix/rich-content-features';
import { hasSuspiciousFields, textToDraftContent } from '../../common/services/captcha-helpers';
import {
  createHash,
  tokenName,
  getUniquePostPayloadString,
} from '@wix/communities-forum-universal/dist/src/services/forum-hs';

export const CREATE_POST_REQUEST = 'post/CREATE_REQUEST';
export const CREATE_POST_SUCCESS = 'post/CREATE_SUCCESS';
export const CREATE_POST_FAILURE = 'post/CREATE_FAILURE';
export const CAPTCHA_ERROR_CODE = 15;

export const createPostRequest = createAction(CREATE_POST_REQUEST);
export const createPostSuccess = createAction(
  CREATE_POST_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const createPostFailure = createAction(
  CREATE_POST_FAILURE,
  () => undefined,
  meta => meta,
);

export function createPost(post = {}, token) {
  return (dispatch, getState, { request }) => {
    dispatch(createPostRequest());
    const postSanitized = sanitizeContent(post);
    const body = {
      ...postSanitized,
      hashtags: extractHashtagsFromContent(postSanitized.content),
      mentions: extractMentionsFromContent(postSanitized.content),
    };

    const isCaptchaUsed = !!token;

    const tokenQuery = isCaptchaUsed ? `token=${token}` : '';
    const promise = request.post(
      `/posts/?${tokenName}=${createHash(getUniquePostPayloadString(postSanitized))}&${tokenQuery}`,
      body,
      {
        noRetriesOnErrors: [403, 503, 504],
      },
    );

    return promise
      .then(
        post => dispatch(createPostSuccess(post, { isCaptchaUsed })),
        response => dispatch(createPostFailure(response)),
      )
      .then(() => promise);
  };
}

export const createPostAndNavigateToItPromisified = onBeforePostCreateHook =>
  createPromisifiedAction(
    postRaw => {
      return async (dispatch, getState, { wixCodeApi }) => {
        const {
          isServerCaptchaExperimentEnabled,
          isCaptchaExperimentEnabled,
          isCaptchaByFlagExperimentEnabled,
          isCaptchaByContentForced,
        } = getEnabledCaptchaExperiments(getState);

        const {
          enableOnPostCreation: isCaptchaOnPostEnabled,
          enableOnSuspiciousActivity: isCaptchaOnSuspiciousEnabled,
        } = getCaptchaSettings(getState());

        const isForumSpammed = getIsForumSpammed(getState());

        let isCaptchaForContent = false;
        const isCaptchaForced = isCaptchaExperimentEnabled;
        const isCaptchaForSpammedForum = isCaptchaByFlagExperimentEnabled && isForumSpammed;

        if (isCaptchaByContentForced || isCaptchaOnSuspiciousEnabled) {
          const contentFeatures = getRichContentFeatures(postRaw.content);
          const titleFeatures = getRichContentFeatures(textToDraftContent(postRaw.title));

          isCaptchaForContent =
            hasSuspiciousFields(contentFeatures) || hasSuspiciousFields(titleFeatures);
        }

        let token;

        if (
          (isCaptchaForced ||
            isCaptchaForSpammedForum ||
            isCaptchaForContent ||
            isCaptchaOnPostEnabled ||
            // Temporary, should be removed, when decision on default captcha settings is made
            (isCaptchaByContentForced && isCaptchaForContent)) &&
          !isServerCaptchaExperimentEnabled
        ) {
          token = await getCaptchaToken(dispatch, wixCodeApi);
          if (!token) {
            return Promise.resolve({
              isSuccessful: false,
            });
          }
        }

        let promise = Promise.resolve();
        if (onBeforePostCreateHook.hasHook()) {
          promise = promise.then(() =>
            onBeforePostCreateHook.exec(postRaw).catch(createExternalHookRejectHandler(dispatch)),
          );
        }

        let postToUse;

        return promise
          .then(r => {
            postToUse = isExternalHookResolve(r) ? r.payload || postRaw : postRaw;
            return dispatch(createPost(postToUse, token));
          })
          .catch(async response => {
            if (
              response.data.errorCode !== CAPTCHA_ERROR_CODE ||
              !isServerCaptchaExperimentEnabled
            ) {
              return Promise.reject(response);
            }

            token = await getCaptchaToken(dispatch, wixCodeApi);
            return token
              ? dispatch(createPost(postToUse, token))
              : // eslint-disable-next-line prefer-promise-reject-errors
                Promise.reject(response);
          })
          .then(post => {
            const category = getCategory(getState(), post.categoryId);
            dispatch(closeModal({ type: MODAL_TYPE_CREATE_POST, resolve: false }));
            dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
          })
          .catch(response => ({
            isSuccessful: false,
            status: response.status,
          }));
      };
    },
    ({ isSuccessful = true, status } = {}) => ({ isSuccessful, status }),
  );

const getCaptchaToken = async (dispatch, wixCodeApi) => {
  dispatch(userEventsPostCaptchaShown());
  const token = await wixCodeApi.authentication.openCaptchaChallenge();
  dispatch(userEventsPostCaptchaResolved(!!token));

  return token;
};

const getEnabledCaptchaExperiments = getState => {
  const isServerCaptchaExperimentEnabled = isExperimentEnabled(
    getState(),
    EXPERIMENT_ENABLE_SERVER_CAPTCHA,
  );

  const isCaptchaExperimentEnabled = isExperimentEnabled(
    getState(),
    EXPERIMENT_CREATE_POST_CAPTCHA,
  );
  const isCaptchaByFlagExperimentEnabled = isExperimentEnabled(
    getState(),
    EXPERIMENT_CREATE_POST_CAPTCHA_BY_SPAMMED_FLAG,
  );
  const isCaptchaByContentForced = isExperimentEnabled(
    getState(),
    EXPERIMENT_FORCE_CAPTCHA_BY_CONTENT,
  );

  return {
    isServerCaptchaExperimentEnabled,
    isCaptchaExperimentEnabled,
    isCaptchaByFlagExperimentEnabled,
    isCaptchaByContentForced,
  };
};
