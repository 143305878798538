import createAction from '../../common/services/create-action';

export const MARK_POSTS_AS_SEEN_REQUEST = 'post/MARK_POSTS_AS_SEEN_REQUEST';
export const MARK_POSTS_AS_SEEN_SUCCESS = 'post/MARK_POSTS_AS_SEEN_SUCCESS';
export const MARK_POSTS_AS_SEEN_FAILURE = 'post/MARK_POSTS_AS_SEEN_FAILURE';

const markPostsAsSeenRequest = createAction(MARK_POSTS_AS_SEEN_REQUEST);
const markPostsAsSeenSuccess = createAction(MARK_POSTS_AS_SEEN_SUCCESS);
const markPostsAsSeenFailure = createAction(MARK_POSTS_AS_SEEN_FAILURE);

export function markPostAsRead(postsIds) {
  return (dispatch, getState, { request }) => {
    dispatch(markPostsAsSeenRequest({ postsIds }));

    const promise = request.put('/platformized/v1/posts/batch/mark-seen', { postsIds });
    return promise
      .then(
        () => dispatch(markPostsAsSeenSuccess({ postsIds })),
        error => dispatch(markPostsAsSeenFailure({ postsIds }, error)),
      )
      .then(() => promise);
  };
}
