import createBiMiddleware from '../middleware/bi-middleware';
import { parseInstance } from '@wix/communities-forum-client-commons/dist/src/services/parse-instance';
import { FORUM_APP_ID } from '../constants/applications';
import { getCurrentInstance } from '../../common/services/get-instance';

function getOptions(bi, isSite, wixCodeApi, instanceId) {
  const { visitorId, metaSiteId } = bi;
  const instance = parseInstance(getCurrentInstance(wixCodeApi)) || {};
  return {
    defaults: {
      biToken: metaSiteId,
      application_type: 'web',
      app_site_id: instanceId,
      instance_id: instanceId,
      _visitorId: visitorId || instance.uid,
      ...getBiFieldsOverride(),
    },
  };

  function getBiFieldsOverride() {
    if (isSite) {
      return {};
    }
    const user = wixCodeApi.user.currentUser;
    return {
      _msid: instance.metaSiteId,
      _appId: FORUM_APP_ID,
      _instanceId: instanceId,
      _siteOwnerId: instance.siteOwnerId,
      _siteMemberId: user.loggedIn ? user.id : '',
    };
  }
}

export function initBiMiddleware(
  platformAPIs,
  events,
  isSite,
  wixCodeApi,
  instanceId,
  config,
  BiLocationTracker,
  forumsUsersEvents,
) {
  const { visitorId, viewerSessionId } = platformAPIs.bi;
  const { schemaLogger, eventMap } = events;
  const options = getOptions(platformAPIs.bi, isSite, wixCodeApi, instanceId);
  if (forumsUsersEvents) {
    const instance = parseInstance(getCurrentInstance(wixCodeApi)) || {};
    if (instance.siteOwnerId) {
      options.defaults._uuid = instance.siteOwnerId;
    }
  }
  const bi = schemaLogger(platformAPIs.biLoggerFactory())(options).bi;
  return createBiMiddleware({
    bi,
    eventMap,
    visitorId,
    viewerSessionId,
    config,
    BiLocationTracker,
    wixCodeApi,
    platformBiProperties: platformAPIs.bi,
    isSite,
  });
}
