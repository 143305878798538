import { POSTS_PER_PAGE } from '../constants/pagination';
import { startLoadingCategory } from './start-loading-category';
import { stopLoadingCategory } from './stop-loading-category';
import fetchPosts from './fetch-posts';
import { actionPromiseCache } from '../services/action-promise-cache';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { markPostAsRead } from './mark-posts-as-seen';

const finishLoading = (dispatch, categoryId) => {
  dispatch(stopLoadingCategory(categoryId));
};

export const fetchCategoryPosts = actionPromiseCache(
  ({ siteMemberId, categoryId, page, sort, postType, pageSize = POSTS_PER_PAGE }) => (
    dispatch,
    getState,
  ) => {
    const state = getState();
    const isRecentActivityCatsEnabled = isExperimentEnabled(state, EXPERIMENT_RECENT_ACTIVITY_CATS);

    dispatch(startLoadingCategory(categoryId));
    const promise = dispatch(
      fetchPosts({ siteMemberId, categoryId, page, pageSize, sort, postType }),
    );

    if (isRecentActivityCatsEnabled) {
      promise.then(response => dispatch(markPostAsRead(response.body.map(p => p._id))));
    }

    return promise
      .then(() => finishLoading(dispatch, categoryId))
      .catch(() => finishLoading(dispatch, categoryId))
      .then(() => promise);
  },
  {
    cacheKeyBuilder: args => `${args.categoryId}${args.page}${args.sort}${args.siteMemberId}`,
    cacheTime: 1000,
    ignoreResolve: true,
  },
);
