import { isEqual } from 'lodash';
import {
  TEMPLATE_CATEGORY_ID,
  FORUM_DATA_CATEGORIES_MANAGER_ID,
} from '@wix/communities-forum-client-commons/dist/src/constants/categories-manager';
import { getCategory } from '../../common/selectors/categories-selectors';
import { getForumData } from './forum-data-selectors';
import {
  getListOfSiteMembersToGiveAccessTo,
  getListOfSiteMembersToRemoveAccessFrom,
} from './manage-category-members-selectors';

export const getState = state => state.categoriesManager;
export const getCategoryInEditId = state => getState(state).categoryInEditId;
export const getFlowType = state => getState(state).flowType;
export const getOpenPanels = state => getState(state).openPanels;
export const getInitialCopy = state => getState(state).categoryCopy;
export const isNewCategory = state => getCategoryInEditId(state) === TEMPLATE_CATEGORY_ID;

export const getCategoryInEdit = state => {
  const id = getCategoryInEditId(state);
  if (id === FORUM_DATA_CATEGORIES_MANAGER_ID) {
    return getForumData(state);
  }
  return getCategory(state, id);
};
export const isCategoryEdited = state => {
  const original = getInitialCopy(state);
  const current = getCategoryInEdit(state);

  return current && !isEqual(original, current);
};
export const isForumDataEdited = state => {
  const original = getInitialCopy(state);
  const current = getForumData(state);

  return current && !isEqual(original, current);
};

export const isCategoryFormEdited = state => {
  return (
    isCategoryEdited(state) ||
    getListOfSiteMembersToGiveAccessTo(state).length > 0 ||
    getListOfSiteMembersToRemoveAccessFrom(state).length > 0
  );
};

export const shouldShowLoader = state => getState(state).showLoader;
